import React, { useState, useEffect, useReducer, useMemo , useRef} from "react";
import ReactPaginate from "react-paginate";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { utils, writeFile } from 'xlsx';

import request from "services/request";
import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import DeleteModal from "views/commonViews/DeleteModal";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import { cloneDeep } from "common/utils.ts";
import { useLoading } from "contexts/LoadingContextManagement";

import MealModal from "./mealModel";
import RestaurantModel from "./restaurantModel"

import reducer, { ACTION_TYPES, initialState } from "./reducer";
import {
  CARBON_MEAL_CATEGORIES,
  DEFAULT_ERROR_MESSAGE,
  ITEMS_PER_PAGE,
} from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { getProvidersDummy, mealsKeys, requiredMealsKeys } from "../../settings/utils";
import carbon_footprint_recipes  from "../../../data/carbon_footprint_recipes.json"

import Addicon from "assets/images/icon/ADD_WHITE.svg";
import Trashicon from "assets/images/icon/filter.svg";
import EyeIcon from "assets/images/icon/eye.svg";
import TelechargerIcon from "assets/images/telecharger.png";
import DashboardPage from "./index"
import storage from "services/storage";

import "./style.scss";

let isMountLoaded = false;

function MyDashboardTable() {
  const { t, i18n } = useTranslation();
  const tableRef = useRef(null);
  const { setLoading, setError } = useLoading();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [restaurantShow, setRestaurantShow] = useState(false);
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  const [isMyMeals, setIsMyMeals] = useState(true);

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sleaveState, setSleaveState] = useState({
    index: -1,
    isOpen: true,
  });
  const is_demo = JSON.parse(storage.getItem("is_demo"));
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants, selectedRestaurant } =
    useUserData();

    console.log({selectedRestaurant})

  const tableColumns = [
    {
      caption: t("Code Hotel"),
      className: "fw-bold",
      style: { width: "150px", display: "flex" },
      headerStyle: { width: "100px" },
      type: "customRender",
      render: (_, it) => (
        <span>{storage.getItem("hotel_code") || "HA595"}</span>
      ),
    },
    {
      dataField: "name",
      caption: t("Meal"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "restaurant.name",
      caption: t("Restaurant"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        return <span>{selectedRestaurant?.name}</span>
      }
    },
    {
      dataField: "weight_in_kg",
      caption: t("Quantity (kg)"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        const totalWeight = (it.carbon_footprint_ingredients?.reduce((acc, item) => {
          let ingredient_unit = item.CarbonFootprintIngredientMeal.ingredient_unit 
          return acc + (ingredient_unit === 'g' ? item.CarbonFootprintIngredientMeal?.ingredient_quantity/1000 : item.CarbonFootprintIngredientMeal?.ingredient_quantity)
        }, 0))?.toFixed(3)
        return <span style={totalWeight > 1.5 ? {color: 'red'} : {}}>{totalWeight}</span>
      }
    },
    {
      // dataField: "category",
      caption: t("Category"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        const category = (CARBON_MEAL_CATEGORIES.find((item) => {
          if(item.id === it?.type || item.id === it.category) {
            return item
          }
        }))?.label
        return <span>{t(category)}</span>
      }
    },
    {
      caption: t("Carbon impact (in Kg CO2eq)"),
      className: "text-center",
      headerStyle: { whiteSpace: "normal"},
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        return <span>{(it.carbon_footprint_ingredients.reduce((acc, item) => {
          let ingredient_unit = item.CarbonFootprintIngredientMeal.ingredient_unit 
          return acc + ((ingredient_unit === 'g' ? item.CarbonFootprintIngredientMeal?.ingredient_quantity/1000 : item.CarbonFootprintIngredientMeal?.ingredient_quantity) * item.climate_change)
        }, 0)/it.servings)?.toFixed(4)}</span>
      }
    },
    {
      dataField: "action",
      caption: "Action",
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => (
        <img
          src={EyeIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    },
  ];

const onDownload = () => {
  // Convert table to sheet
  const ws = utils.table_to_sheet(tableRef.current);

  // Add static data
  const staticData = [
    [`Restaurant name: ${selectedRestaurant.name}`], // A1
    [], // A2
    [], // A3 (empty row)
  ];

  // Combine static data and table data
  const tableData = utils.sheet_to_json(ws, { header: 1 }); // Convert existing sheet data to array
  const combinedData = [...staticData, ...tableData]; // Combine static and table data

  // Create a new sheet with combined data
  const newWs = utils.aoa_to_sheet(combinedData);

  // Adjust the file name
  const fileName = i18n.language === 'fr' 
    ? `${selectedRestaurant.name}_Extraction plats_${new Date().toLocaleDateString()}`
    : `${selectedRestaurant.name}_Meals extraction_${new Date().toLocaleDateString()}`;

  // Create a new workbook and append the sheet
  const wb = utils.book_new();
  utils.book_append_sheet(wb, newWs, 'Sheet1');

  // Download the Excel file
  writeFile(wb, `${fileName}.xlsx`);
};

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      const result = await getMeals(event.selected + 1);
      setCurrentItems(() => result.cf_meals);
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    if(selectedRestaurantId) {
      getInitialData()
    }
    if (isRestaurantLoaded && !hasRetaurants && is_demo) {
      getMeals()
      getAllergens()
      getProviders()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, isRestaurantLoaded, hasRetaurants]);

  useEffect(() => {
    if (!isMountLoaded) {
      return;
    }
      fetchAndSetMeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fd.search, fd.sortby, fd.orderBy]);

  const fetchAndSetMeals = async () => {
    try {
      const result = await getMeals(currentPage+1);
      dispatch({ type: ACTION_TYPES.MULTI, meals: result.cf_meals });
      setCurrentItems(() => result.cf_meals);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };
// carbon_footprint_recipes
  const getMeals = (page = 1) => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setCurrentItems(() => carbon_footprint_recipes.cf_meals);
      setPageCount(1);
      setCurrentPage(0);
      return 
    }
    let payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };
    return request.get(`carbon-footprint-meals`, payload);
  };

  const getAllergens = () => request.get("allergens");

  const getProviders = () => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return getProvidersDummy();
    }
    return request.get(`providers?restaurant_id=${selectedRestaurantId}`);
  };

  const getInitialData = async () => {
    setLoading(true);
    try {
      const [meals, allergens, providers] = await Promise.all([
        getMeals(),
        getAllergens(),
        getProviders(),
      ]);
      dispatch({
        type: ACTION_TYPES.MULTI,
        payload: {
          meals: meals.cf_meals,
          allergens: allergens.allergens,
          providers: providers.providers,
        },
      });
      setCurrentItems(() => meals.cf_meals);
      setPageCount(meals.total_pages);
      isMountLoaded = true;
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const showRow = (row) => () => {
    setSelectedMeal({ ...row, selling_price_per_person: row.price_per_person });
    setModalShow(true);
  };

  const onRowAdded = () => {
    fetchAndSetMeals();
    setSelectedMeal(null);
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedRows = cloneDeep(selectedRows);
      if (checked) {
        setSelectedRows(() => [...newSelectedRows, it.id]);
      } else {
        setSelectedRows(() => newSelectedRows.filter((p) => p !== it.id));
      }
    };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((f) => f.id)] : []));
  };

  const deleteRows = async () => {
    try {
      const result = await request.delete(`/carbon-footprint-meals`, { cf_meal_ids: selectedRows });
      if (result.status === 200) {
        setSelectedRows([]);
        setCurrentItems(() =>
          currentItems.filter((i) => !selectedRows.includes(i.id))
        );
      }
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onModalHide = () => {
    setSelectedMeal(null);
    setModalShow(false);
  };

  const processedCurrentItems = useMemo(() => {
    const newCurrentItems = [...currentItems];
    if(sleaveState.index !== -1) {
      newCurrentItems[sleaveState.index] = {...newCurrentItems[sleaveState.index], prediction: { isOpen: sleaveState.isOpen }};
    }
    return newCurrentItems;
  }, [currentItems, sleaveState])

  return (
    <>
    {
       modalShow2 ? <DashboardPage/> :
      <div className="my-ingredients">
        <MealModal
          show={modalShow}
          onHide={onModalHide}
          onRowAdded={onRowAdded}
          setSelectedMeal={setSelectedMeal}
          state={{ ...state, ...(selectedMeal && { selectedMeal }) }}
        />

        <RestaurantModel
          show={restaurantShow}
          onHide={setRestaurantShow}
          onRowAdded={onRowAdded}
        />

        <DeleteModal
          show={deleteModalShow}
          onHide={() => setDeleteModelShow(false)}
          onPositiveClicked={() => {
            setDeleteModelShow(false);
            deleteRows();
          }}
          modalData={{
            title: t("Delete meals"),
            description: t(
              "Are you sure you want to delete meals ? You cannot undo this action."
            ),
            positiveBtnTitle: t("Yes, delete meals"),
          }}
        />

        <Row>
          <h2>{t("WelcomeDashboard")}</h2>
          <span className="add-restaurants-input-title">
            {t("AddOrModifyInformationOfDishes")}
          </span>      
        </Row>
        <Row style={{marginLeft:"0px"}}>
          <div className="d-flex row dashboard-table" style={{marginTop: "50px"}}>
            {isMyMeals && 
            <Col xs="auto">
              <Button
                variant="primary add-btn-container"
                onClick={() => setModalShow(true)}
              >
                <img src={Addicon} className="add-btn-icon" alt="..." />
                {t("AddARecipe")}
              </Button>
            </Col>
            }
            {isMyMeals && <Col xs="auto">
              <Button
                variant="primary add-btn-container"
                onClick={() => setRestaurantShow(true)}
              >
                <img src={Addicon} className="add-btn-icon" alt="..." />
                {t("AddARestaurant")}
              </Button>
            </Col>}
            <Col className="sort-container d-flex justify-content-end">
              {Boolean(selectedRows.length) && (
                <img
                  src={Trashicon}
                  onClick={() => setDeleteModelShow(true)}
                  className="me-3"
                  alt="..."
                />
              )}

              <input
                type="search"
                className="search-input me-3"
                placeholder={t("Search")}
                onChange={(e) => setfd({ ...fd, search: e.target.value })}
              />

              <button
                className="btn btn-white btn-icon me-3"
                style={{ height: "38px" }}
                onClick={onDownload}
              >
                <img src={TelechargerIcon} alt="" className="m-0" style={{height:"24px"}}/>
              </button>

              <SortFilter
                cols={tableColumns}
                fd={fd}
                setfd={setfd}
                rootClassName="sort-filter"
              />
            </Col>
          </div>
        </Row>
        <div className="mt-3 table-container">
          <CustomTable
            ref={tableRef}
            columns={tableColumns}
            data={processedCurrentItems}
            editRow={showRow}
            selectChange={selectChange}
            selectedProducts={selectedRows}
            selectAllProducts={selectAllRows}
          />

          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-end mt-2"> 
                <ReactPaginate
                  nextLabel={`${t("Next")}   >`}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={`<   ${t("Back")}`}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    }
    </>
  );
}

export default MyDashboardTable;
